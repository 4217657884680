@import '../utilities/variables';

@mixin create-mq($breakpoint, $min-or-max) {
  @if($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    .col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-#{$i} {
        flex-basis: (100 / ($grid-cols / $i) ) * 1%;
      }
      .col#{$modifier}-offset-#{$i} {
        margin-left: (100 / ($grid-cols / $i) ) * 1%;
      }
    }
  }
}

@each $modifier , $breakpoint in $map-grid-props {
  @if($modifier == '') {
    $modifier: '-xs';
  }
  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  flex: 1;
  flex-wrap: wrap;

  &-fluid {
    margin: 0;
    max-width: 100%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @each $modifier , $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}

.container {  
  .row {
    min-height: 30px;
    margin: 1% 0;
  }

  @at-root {
    [class*='col-'] {
      padding-right: 1%;
      height: 60px;
    }
  }

  @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
    font-size: 12px;
    input, select, date-picker { 
      font-size: 12px;
    }
  }
  
  @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
    font-size: 13px;
    input, select, date-picker { 
      font-size: 13px;
    }
  }
  
  @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) { // Ipad Pro
    font-size: 14px;
    input, select, date-picker { 
      font-size: 14px;
    }
  }
  @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
    font-size: 15px;
    input, select, date-picker { 
      font-size: 15px;
    }
  }
}

@mixin create-dimensions-classes($modifier) {
  @for $i from 1 through 100 {
    .m#{$modifier}-#{$i} {
      margin-#{$modifier}: $i * 1%;
    }
    .p#{$modifier}-#{$i} {
        padding-#{$modifier}: $i * 1%;
    }
}
}

@each $modifier in $map-orientations-props {
@include create-dimensions-classes($modifier);
}

    