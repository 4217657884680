// Colors
$primary: #FF4400;
$primary-active: rgb(255, 121, 68);
$primary-80: #FF8400;
$primary-dark: #aa3a0d;
$error: #f00;

$checked-hover: rgba(210, 91, 44, 0.8);
$unchecked-hover: rgba(210, 91, 44, 0.1);

$main: #FFF;
$secondary: #D3D3D3; 
$black: #4D4D4D;
$green: rgb(76, 165, 3);

// Breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// Header
$header-height: 70px;

// Sidebar
$sidebar-mini-width: 60px;
$sidebar-open-width: 240px;

//Spacing
$spacing: 8px;

// Font
$font-family: 'Bahnschrift';

// Grid
$grid__cols: 12;

$map-grid-props: (
    '': 0,
    '-sm': $breakpoint-sm,
    '-md': $breakpoint-md,
    '-lg': $breakpoint-lg,
    '-xl': $breakpoint-xl
);

$map-orientations-props: (
    'top',
    'right',
    'bottom',
    'left'
);
