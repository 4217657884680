@import '../../styles/scss/utilities/variables';

.login-root {
    min-height: 100vh;
    position: absolute;
    animation: fadeIn 500ms;
  }
  
  .login-image {
    background-image: url("../../images/loginBkgNelore.jpg");
  }
  
  .login-container-form {
    display: flex;
    align-items: center;
  }
  
  .login-paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
      margin: 30px;
      padding: 30px;
    }
  
    @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
      margin: 40px;
      padding: 40px;
    }
  
    @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { // Ipad Pro
      margin: 40px;
      padding: 40px;
    }
  
    @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
      margin: 70px;
      padding: 50px;
    }
  }
  
  .login-logo {
    width: 200px;
    height: 90px;
  
    @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
      width: 170px;
      height: 90px;
    }
  }
  
  .login-form {
    margin-top: 8px;
  }
  
  .login-box-forgot-password {
    display: flex;
    justify-content: flex-end;  
    margin-bottom: 20px;
  }