
@import './src/styles/scss/base/_grid';

.rangeValues {
    @extend .container;
    font-weight: bold;
    color: $black;

    .info {
      color: $primary;
    }
}

@mixin range-slider($width, $height, $input-top, $input-bg-color, $input-thumb-color, $float:none, $input-height:20px, $input-border-radius:14px) {
    position: relative;
    width: $width;
    height: $height;
    float: $float;
    text-align: center;

    input[type=number] {
      width: 25%;
    }
      
    input[type="range"] {
      pointer-events: none;
      position: absolute;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);    
      border: none;
      border-radius: $input-border-radius;
      background: $input-bg-color;
      box-shadow: inset 0 1px 0 0 darken($input-bg-color,15%), inset 0 -1px 0 0 darken($input-bg-color,10%);
      -webkit-box-shadow: inset 0 1px 0 0 darken($input-bg-color,15%), inset 0 -1px 0 0 darken($input-bg-color,10%);
      overflow: hidden;
      left: 0;
      top: $input-top;
      width: $width;
      outline: none;
      height: $input-height;
      margin: 0;
      padding: 0;
    }  
    
    input[type="range"]::-webkit-slider-thumb {
      pointer-events: all;
      position: relative;
      z-index: 1;
      outline: 0;    
      -webkit-appearance: none;
      width: $input-height;
      height: $input-height;
      border: none;
      border-radius: $input-border-radius;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, lighten($input-thumb-color,60%)), color-stop(100%, $input-thumb-color)); /* android <= 2.2 */
      background-image: -webkit-linear-gradient(top , lighten($input-thumb-color,60%) 0, $input-thumb-color 100%); /* older mobile safari and android > 2.2 */;
      background-image: linear-gradient(to bottom, lighten($input-thumb-color,60%) 0, $input-thumb-color 100%); /* W3C */
    }
    
    input[type="range"]::-moz-range-thumb {
      pointer-events: all;
      position: relative;
      z-index: 10;
      -moz-appearance: none;
      width: $input-height;
      height: $input-height;
      border: none;
      border-radius: $input-border-radius;
      background-image: linear-gradient(to bottom, lighten($input-thumb-color,60%) 0, $input-thumb-color 100%); /* W3C */
    }
  
    input[type="range"]::-ms-thumb {
      pointer-events: all;
      position: relative;
      z-index: 10;
      -ms-appearance: none;
      width: $input-height;
      height: $input-height;
      border-radius: $input-border-radius;
      border: 0;
      background-image: linear-gradient(to bottom, lighten($input-thumb-color,60%) 0, $input-thumb-color 100%); /* W3C */
    }
    
    input[type=range]::-moz-range-track {
      position: relative;
      z-index: -1;
      background-color: rgba(0, 0, 0, 1);
      border: 0;
    }
    
    input[type=range]:last-of-type::-moz-range-track {
      -moz-appearance: none;
      background: none transparent;
      border: 0;
    } 
    
    input[type=range]::-moz-focus-outer {
      border: 0;
    }
  }
  
  section.range-slider {
    @include range-slider(300px, 100px, 50px, #F1EFEF, #413F41, left);
  }
  