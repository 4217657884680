@import '../../../src/styles/scss/utilities/variables';
@import '../../../src/styles/scss/base/_title';

.monitoring-table .card { 
    padding: 0%;
    margin-bottom: 0%;

    &-content {
        @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
            margin: 0%;
        }
        
        @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
            margin: 0%;
        }
        
        @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { // Ipad Pro
            margin: 0%;
        }
        @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
            margin: 0%;
        }

        .title {
            display: flex;
            flex-direction: row;
            p {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}