@import '../../../styles/scss/utilities/variables';

.main-button {
  display: flex;
  button {
    flex: 1;
    outline: none;
    font-size: 16px;
    background-color: $primary;
    color: $main;
    border-radius: 5px;
    border: 0;
    padding: 2%;
    &:hover, &:active, &:focus {
      background-color: $primary-active;
    }
  }

}