@import '../../../styles/scss/utilities/variables';
@import '../Input/styles.scss';

.div { @extend .div; }

.select {
  &-input {
    width: 100%;
    min-width: 150px;
    &:disabled { background-color: rgb(235, 235, 228); }
    @extend .input;
    input {
      flex: 1;
      padding: 1% 1%;
      border: 0;
      width: 100%;
      outline: none;
      margin: 1px;
      // &:disabled { background-color: $main; }
    }
    .icon {
      outline: none;
      border: 0;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      background-color: $main;
    }
  }
  .options {
    position: relative;
    ul {
      box-shadow: 0 3px 5px -1px rgba(71, 49, 49, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
      position: absolute;
      z-index: 3;
      width: 100%;
      height: auto;
      max-height: 250px;
      overflow: auto;
      list-style-type: none;
      background: $main;
      border: 1px solid $secondary;
      border-radius: 8px;
      padding: 0;
      margin: 0;
      .checked { color: $primary; }
      .focused { background: $secondary; }
      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 16px;
        &:hover {
          background: $secondary;
        }
      }
    }
  }

}