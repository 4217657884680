.title {
    font-family: $font-family;
    
    @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
        font-size: 14px;
    }
    
    @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
        font-size: 16px;
    }
    
    @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { // Ipad Pro
        font-size: 18px;
    }
    @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
        font-size: 20px;
    }
}
.subtitle {
    font-family: $font-family;
    
    @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
        font-size: 6px;
    }
    
    @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
        font-size: 8px;
    }
    
    @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { // Ipad Pro
        font-size: 10px;
    }
    @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
        font-size: 12px;
    }
}
