@import '../../../styles/scss/utilities/variables';

.div {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 15px;
  width: 100%;
  min-width: 200px;
  height: 100%;

  .input[value=''] {
    height: 100%;
  }

  .input {
    display: flex;
    align-items: center;
    padding: 1%;
    flex: 1;
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid $black;
    border-radius: 6px;
    min-width: 150px;
    
    &:hover { border: 1px solid #000; }
    
    &:focus, &:focus-within {
      border: 2px solid $primary;
      ~label { color: $primary; }
      &:hover { @extend :focus }
    }
  }

  &-error {
    @extend .div;
    color: $error;
    
    .input {
      border: 2px solid $error;
      &:hover { @extend .input }
    }
  }
}
