@import '../../../styles/scss/utilities/variables';

.drawer {
    width: $sidebar-open-width;
    flex-shrink: 0;
    z-index: 0;  
    white-space: nowrap; 
    &-toolbar {
        min-height: 80px;
    }
    &-opened {
        width: $sidebar-open-width;
        animation: openSidebar 300ms;
    }
    &-closed {
        animation: closeSidebar 300ms;
        overflow-x: hidden;
        width: $spacing * 7;
    }
    &-nav-item {
        margin: 10px;
    }
    
}
