@import '../../../styles/scss/utilities/variables';

.container-root {
    display: flex;
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
}

.container-content {
    flex-grow: 1;
    padding: $spacing * 4;
    padding-top: $spacing * 7;
}

.container-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px $spacing;
    min-height: 46px;
}