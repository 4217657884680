@import './src/styles/scss/utilities/_variables.scss';

.pen-list {
    div[class^='MTableToolbar-actions'] {
        width: 100%;
    }
    .MuiToolbar-gutters {
        div[class^='jss'] {
            width: 100%;
        }
    }

    .MuiTableRow-footer .MuiToolbar-gutters {
        div[class^='jss'] {
            width: auto;
        }
    }

    .MuiTablePagination-root:last-child {
        padding: 1px;
    }
}
span {
    button {
        margin-left: 1%;
    }
    .download-button {
        box-shadow: 2px 3px $primary;
        width: fit-content;
        border: 1px solid $primary;
        background-color: $main;
        color: $primary;
        border-radius: 5%;
        font-size: 1rem;
        border-radius: 5%;
        .csv-reader-input { 
            width: 100px;
            cursor: pointer;
            .file-upload-button,        
            .csv-label,
            .csv-input {
                cursor: pointer;
            }
        }
        p {   
            display: flex;
            justify-content: center;
        }
    }
    .download-button:hover, .download-button:active, .download-button:focus {
        box-shadow: 0px 0px $primary-active;
        border: 2px solid $primary-active;
        color: $primary;
    }
}