@import '../../../styles/scss/utilities/variables';
@import '../Input/styles.scss';

@mixin chip($status) {
  outline: none;
  padding: 10px 20px;
  margin: 3px;
  border-radius: 25px;
  font-family: $font-family;
  font-size: 14px;
  cursor: pointer;

  @if ($status == 'checked') {
    background-color: $primary;
    border: 1px solid $primary;
    color: $main;
  } @else {
    background-color: $main;
    border: 1px solid $primary;
    color: $primary;
  }
  
  &:focus {
    @if ($status == 'checked') {
      box-shadow: 1px 1px 1px 1px $primary-dark;
    } @else {
      box-shadow: 1px 1px 1px 1px $primary;
    }
  }
  &:hover {
    @if ($status == 'checked') {
      background-color: $checked-hover;
    } @else {
      background-color: $unchecked-hover;
    }
  }
  input[type=checkbox] {
    display: none;
  }
}

.chip {
  &-checked {
    @include chip('checked');
  }
  &-unchecked {
    @include chip('unchecked');
  }
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;
}
