@keyframes fadeIn {
    from {
      opacity: 0;
      // transform: translateX(-35%); // scale(0.9)
    }
  
    to {
      opacity: 1;
      // transform: translateX(0); // scale(1)
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      // transform: scale(0.9);
    }
  
    to {
      opacity: 0;
      // transform: scale(1);
    }
  }
  
  @keyframes openSidebar {
    from {
      width: $spacing * 7;
    }
  
    to {
      width: $sidebar-open-width;
    }
  }
  
  @keyframes closeSidebar {
    from {
      width: $sidebar-open-width;
    }
  
    to {
      width: $spacing * 7;
    }
  }
  
  
  @keyframes shake {
    0% {
      transform: translateX(0)
    }
  
    20% {
      transform: translateX(-5%)
    }
  
    40% {
      transform: translateX(5%)
    }
  
    60% {
      transform: translateX(-5%)
    }
  
    80% {
      transform: translateX(5%)
    }
  
    100% {
      transform: translateX(0)
    }
  }
  