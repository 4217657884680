@import '../../../styles/scss/utilities/variables';
@import '../Input/styles.scss';

.div { @extend .div; }

.date-picker {
  @extend .input;
}

.react-datepicker__day--selected { background: $primary; }
.react-datepicker__day--today {
  background: $secondary;
  color: $black;
  &:hover {
    background: $unchecked-hover;
  }
}