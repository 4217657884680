@import '../utilities/variables';
@import url('https://fonts.googleapis.com/css?family=Bahnschrift&display=swap');

body {
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  background-color: #f5f5f5;
  overflow-x: hidden;
  font-family: 'Bahnschrift', sans-serif;  
}

::-webkit-scrollbar {
  @media screen and (max-width: $breakpoint-sm) {
    // Small
    width: 0px;
  }

  @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    // Medium
    width: 0px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    // Large
    width: 10px;
  }
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c0bcbc;

  &:hover{
    background: #555;
  }
}

