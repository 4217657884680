@import '../../../styles/scss/utilities/variables';
@import '../../../styles/scss/base/_title';

.card { 
    padding: 1%;
    margin-bottom: 2%;

    &-content {
        @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
            margin: 5%;
        }
        
        @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
            margin: 3%;
        }
        
        @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { // Ipad Pro
            margin: 2%;
        }
        @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
            margin: 1%;
        }
    }
}

