@import '../../../styles/scss/utilities/variables';

@mixin item-list($checked) {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  background-color: $main;
  border: 1px solid $secondary;
  @if $checked == 'true' { border: 1px solid $primary; }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    .fixed {
      display: flex;
      justify-content: flex-start;
    }
    input[type=checkbox] {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }
    .icon {
      outline: none;
      border: 0;
      cursor: pointer;
      background-color: $main;
    }
  }
  .list { 
    margin: 10px; 
    font-size: 14px;
    // .loading {
    //   flex: 1;
    //   display: flex;
    //   justify-content: center;
    // } 
  }
}

.item-list {
  &-checked { @include item-list('true') }
  &-unchecked { @include item-list('false') }
}