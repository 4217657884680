@import '../../../styles/scss/utilities/variables';

.content {
  margin-top: 20px;
}

.footer {
  flex: 1;
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 50px;
}
