@import '../../../styles/scss/utilities/variables';

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family;
    width: 100%;
    
    &-paper {
        flex-direction: column;
        overflow: auto;
        max-height: 100%;
        background-color: $main;
        // width: fit-content;
        min-width: 90%;
        box-shadow: 0px 3px 5px -1px rgba(71, 49, 49, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);

        .title {
            display: flex;
            font-size: 20px;
            justify-content: space-between;
        }
        
        @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
            margin: 20%;
            padding: 1%;
        }
        
        @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
            margin: 20%;
            padding: 1%;
        }
        
        @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) { // Ipad Pro
            margin: 20%;
            padding: 1%;
        }
        @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) { // Tela
            margin: 20%;
            padding: 1%;
        } 
    }
    &-content {
        overflow: auto;
        // flex: 1;
        // padding: 2%;
        // min-width: 400px;
        // min-height: 100px;
        // max-width: 800px;
        // max-height: 400px;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) { // Iphone
            font-size: 14px;
            padding: 5%;
        }
        
        @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) { // Ipad
            font-size: 15px;
        }
        
        @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) { // Ipad Pro
            font-size: 16px;
        }
    }

}

