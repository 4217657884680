@import '../utilities/variables';

.active {
    color: $main;
    background-color: #009432;
}

.inactive {
    color: $main;
    background-color: #c0392b;
}

.is-third-part-active {
    color: $main;
    background-color: #2980b9;
}

.is-third-part-inactive {
    color: $main;
    background-color: #636e72;
}

.admin {
    border: solid 1px $primary;
    color: $primary;
}

.field {
    width: 100px;
    font-size: 12px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    text-align: center;
    padding: 6px 8px 6px 8px;
    border-radius: 3px;
}

.MuiAccordionDetails-root {
    display: block !important;
    padding: 0px !important;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex-direction: column;
}

.space-around {
    justify-content: space-around;
}