@import '../../../styles/scss/utilities/variables';
@import '../Input/styles.scss';

.switch {
  margin-left: 10px;
}

.div-row {
  @extend .div;
  flex-direction: row;
  align-items: center;
}
